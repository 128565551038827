import { StoreContext } from '../../../configuration/StoreContext';
import { computed, action, observable, toJS } from 'mobx';
import { IDisclaimer } from '../models/IDisclaimer';
import { IDisclaimerContent } from '../models/IDisclaimerContent';
import { helpers, utils } from '@kurtosys/ksys-app-template';
import { IFootnoteConfiguration } from '../../Footnote/models';
import { TDisclaimerResponse } from '../../../models/commonTypes';

export class DisclaimerStore {
	storeContext: StoreContext;

	constructor(storeContext: StoreContext) {
		this.storeContext = storeContext;
	}

	@observable.ref disclaimerRecords: TDisclaimerResponse[] = [];

	@computed
	get configuration(): IDisclaimer[] {
		const disclaimerConfiguration = this.storeContext.appStore.getComponentConfiguration('disclaimer');
		return (disclaimerConfiguration && disclaimerConfiguration.disclaimers) || [];
	}

	// get the configuration to setup the footnote disclaimer with the loadDisclaimers
	@computed
	get footnoteConfiguration(): IFootnoteConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration('footnote');
		}
	}

	@computed
	get hasDisclaimers(): boolean {
		return this.disclaimers && this.disclaimers.length > 0;
	}

	@computed
	get heading(): string {
		const disclaimerConfiguration = this.storeContext.appStore.getComponentConfiguration('disclaimer');
		return (disclaimerConfiguration && disclaimerConfiguration.heading) || '';
	}

	@computed
	get disclaimers(): IDisclaimerContent[] {
		if (this.validDisclaimerTypes && this.validDisclaimerTypes.length > 0) {
			const flatDisclaimers = toJS(this.disclaimerRecords).filter(disclaimerRecord =>
				this.validDisclaimerTypes.includes(disclaimerRecord.disclaimerType)).map(disclaimerRecord =>
					disclaimerRecord.disclaimers,
				).flat(1);
			const result: IDisclaimerContent[] = flatDisclaimers.map((flatDisclaimer) => {
				return {
					content: flatDisclaimer.disclaimer,
				};
			});
			return result;
		}
		return [];
	}

	@computed
	get validDisclaimerTypes(): string[] {
		if (this.storeContext) {
			const { selectionStore } = this.storeContext;
			const values = selectionStore.values;

			return this.configuration.filter((disclaimer) => {
				if (disclaimer.conditional) {
					const conditionalHelper = new helpers.ConditionalHelper(disclaimer.conditional);
					return conditionalHelper.matchesWithOptions({ instance: values, executionOptions: this.storeContext.queryStore.executionOptions });
				}
				return true;
			}).map(disclaimer => disclaimer.type);
		}
		return [];
	}

	// Set up all disclaimer types for Attestation
	@computed
	get allDisclaimerTypes(): string[] {
		const disclaimerTypes = this.configuration.map(disclaimer => disclaimer.type);
		if (this.footnoteDisclaimerType) {
			disclaimerTypes.push(this.footnoteDisclaimerType);
		}
		return disclaimerTypes;
	}

	@computed
	get footnoteDisclaimerType(): string | undefined {
		if (this.footnoteConfiguration && this.footnoteConfiguration.text && typeof (this.footnoteConfiguration.text) !== 'string') {
			return this.footnoteConfiguration.text.type;
		}
	}

	@action
	async loadDisclaimers() {
		const DEFAULT_KEY = 'Default';
		if (this.storeContext) {
			this.disclaimerRecords = [];

			if (this.allDisclaimerTypes && this.allDisclaimerTypes.length > 0) {
				const { kurtosysApiStore, translationStore } = this.storeContext;
				const culture = translationStore.culture;
				const options = {
					body: {
						disclaimerType: this.allDisclaimerTypes,
					},
				};
				const allDisclaimerRecords = await kurtosysApiStore.client.disclaimers.list.execute(options);
				this.disclaimerRecords = allDisclaimerRecords.map((disclaimerRecord) => {
					const { disclaimers } = disclaimerRecord;
					if (disclaimers && disclaimers.length > 1) {
						const cultureRecord = disclaimers.find(disclaimer => disclaimer.culture === culture);
						if (cultureRecord) {
							disclaimerRecord.disclaimers = [cultureRecord];
						}
						else {
							const defaultRecord = disclaimers.find(disclaimer => disclaimer.culture === DEFAULT_KEY);
							if (defaultRecord) {
								disclaimerRecord.disclaimers = [defaultRecord];
							}
							else {
								disclaimerRecord.disclaimers = [disclaimerRecord.disclaimers[0]];
							}
						}
					}
					return disclaimerRecord;
				});
			}
			else {
				this.disclaimerRecords = [];
			}
		}
	}

	@computed
	get footnoteDisclaimer() {
		const DEFAULT_KEY = 'Default';
		if (this.storeContext) {

			if (this.footnoteDisclaimerType) {
				const { translationStore } = this.storeContext;
				const culture = translationStore.culture;
				const filteredDisclaimers = this.storeContext.disclaimerStore.disclaimerRecords.filter(disclaimerRecord => disclaimerRecord.disclaimerType === this.footnoteDisclaimerType);
				const disclaimerRecords = filteredDisclaimers.map((disclaimerRecord) => {
					const { disclaimers } = disclaimerRecord;
					if (disclaimers && disclaimers.length > 1) {
						const cultureRecord = disclaimers.find(disclaimer => disclaimer.culture === culture);
						if (cultureRecord) {
							disclaimerRecord.disclaimers = [cultureRecord];
						}
						else {
							const defaultRecord = disclaimers.find(disclaimer => disclaimer.culture === DEFAULT_KEY);
							if (defaultRecord) {
								disclaimerRecord.disclaimers = [defaultRecord];
							}
							else {
								disclaimerRecord.disclaimers = [disclaimerRecord.disclaimers[0]];
							}
						}
					}
					return disclaimerRecord.disclaimers;
				}).flat(1).map((flatDisclaimer: any) => {
					return flatDisclaimer.disclaimer;
				});

				if (!utils.typeChecks.isNullOrEmpty(disclaimerRecords)) {
					return disclaimerRecords[0];
				}
			}
		}

	}
}