import React, { Component } from 'react';
import {
	IFootnoteProps,
	IFootnoteState,
} from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import Text from './styledComponents/Text';

@inject('appStore', 'footnoteStore')
@observer
export class Footnote extends Component<IFootnoteProps, IFootnoteState> {
	static configurationKey: 'footnote' = 'footnote';
	static styleKey: 'footnote' = 'footnote';
	constructor(props: IFootnoteProps) {
		super(props);
	}
	render() {
		const { className, footnoteStore } = this.props;

		if (!footnoteStore) {
			return null;
		}

		const { textProps } = footnoteStore;

		return (
			<Wrapper className={ className }>
				<Text { ...textProps } />
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(Footnote, 'footnote');

