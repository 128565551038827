import React, { Component } from 'react';
import { IAppProps } from './models/IAppProps';
import { IAppState } from './models/IAppState';
import { inject, observer } from 'mobx-react';
import { Modal } from '../Modal/Modal';
import Footnote from '../Footnote/Footnote';
import Wrapper from './styledComponents/Wrapper';
import DebugInfoAction from '../shared/DebugInfoAction';
import NoDataPlaceholder from '@kurtosys/ksys-app-components/dist/components/base/NoDataPlaceholder';
import Disclaimer from '@kurtosys/ksys-app-components/dist/components/overview/Disclaimer';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Loading from '../shared/Loading';
import Grid from '@kurtosys/ksys-app-components/dist/components/base/Grid';
import SkeletonLoader from '../shared/SkeletonLoader';
import Application from '@kurtosys/ksys-app-components/dist/components/base/Application';

@inject('appStore', 'selectionStore', 'disclaimerStore', 'queryStore')
@observer
export class App extends Component<IAppProps, IAppState> {
	static configurationKey: 'app' = 'app';
	static styleKey: 'app' = 'app';
	constructor(props: IAppProps) {
		super(props);
	}

	renderAttestation = () => {
		const { appStore, selectionStore, disclaimerStore, className } = this.props;

		if (!appStore || !selectionStore || !disclaimerStore) {
			return null;
		}
		const { isBootstrapped, skeletonLoader, skeletonLoaders } = appStore;
		if (!isBootstrapped) {
			if (skeletonLoader) {
				return <SkeletonLoader config={ skeletonLoader } variables={ skeletonLoaders && skeletonLoaders.variables } />;
			}
			return <Loading />;
		}
		const { theme, show, showPlaceholder, noDataPlaceholder: noDataPlaceholderText, noDataPlaceholderDisclaimer, libraryComponentsConfiguration, getTranslateFunction, culture, assets, assetCacheOptions, assetRegisters, getAccessibilityTextFunction, components, grid, hasSelectionWizard } = appStore;

		if (!show) {
			return null;
		}

		const noDataPlaceholder =
			(noDataPlaceholderDisclaimer && <Disclaimer { ...noDataPlaceholderDisclaimer } />) ||
			(noDataPlaceholderText && <NoDataPlaceholder>{ noDataPlaceholderText }</NoDataPlaceholder>);

		const translate = getTranslateFunction();
		const accessibilityText = getAccessibilityTextFunction();
		return (
			<Application theme={ theme } configuration={ libraryComponentsConfiguration } translate={ translate } culture={ culture } assets={ assets } assetCacheOptions={ assetCacheOptions } assetRegisters={ assetRegisters } accessibilityText={ accessibilityText }>
				<DebugInfoAction appStore={ appStore } />
				<Wrapper className={ className }>
					{ showPlaceholder && noDataPlaceholder }
					{ !showPlaceholder && <Grid components={ components as any } grid={ grid } /> }
					{ !hasSelectionWizard && <Footnote /> }
				</Wrapper>
			</Application>
		);
	}

	render() {
		const { appStore, className } = this.props;

		if (!appStore) {
			return null;
		}

		if (appStore.isAttestationDisabled) {
			return null;
		}
		const { isAttestationBootstrapped, prelaunchModal, skeletonLoader, skeletonLoaders } = appStore;
		if (isAttestationBootstrapped || prelaunchModal) {
			if (appStore.isAttestationActive) {
				const modalClass = prelaunchModal && !isAttestationBootstrapped ? 'attestation-prelaunch-modal' : '';
				return <Modal className={ modalClass } open={ appStore.isAttestationRequired }>{ this.renderAttestation() }</Modal>;
			}
			return this.renderAttestation();
		}
		if (!appStore.isAttestationActive) {
			if (skeletonLoader) {
				return <SkeletonLoader config={ skeletonLoader } variables={ skeletonLoaders && skeletonLoaders.variables } />;
			}
			return <Loading />;
		}

		return null;
	}
}

export default InjectedStyledComponent(App, 'app');
