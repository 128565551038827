import { IComponentConfiguration } from '@kurtosys/ksys-app-components/dist/models/IComponentConfiguration';


export const LIBRARY_COMPONENTS_CONFIGURATION: IComponentConfiguration = {
	base: {
		grid: {
			components: {

			},
		},
	},
};