import * as React from 'react';
import { appsManager } from '@kurtosys/ksys-app-template';
import styled from 'styled-components';
import { ISkeletonLoader, ISkeletonLoaderVariable } from '../../models/commonTypes';
const SkeletonLoaderHandler = appsManager.SkeletonLoader;

export const SkeletonLoader = (props: { config: ISkeletonLoader, variables?: ISkeletonLoaderVariable[] }) => {
	const { config, variables = [] } = props;
	const skeletonLoader = new SkeletonLoaderHandler(config, variables);
	const Skeleton = styled.div`
		${skeletonLoader.getSkeletonCss() }
	`;
	return <Skeleton />;
};

export default SkeletonLoader;
