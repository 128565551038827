export function obfuscate(value: string): string {
	try {
		return window.btoa(value);
	}
	catch (ex) {
		console.error(ex);
		return value;
	}
}

export function deObfuscate(value: string): string {
	try {
		return window.atob(value);
	}
	catch (ex) {
		console.error(ex);
		return value;
	}
}

export function isObfuscated(value: string): boolean {
	try {
		return window.btoa(window.atob(value)) === value;
	}
	catch (ex) {
		// window.atob(value) throws a DOMException if value is not valid base64, so we can return false here
		return false;
	}
}