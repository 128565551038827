import React, { Component, Fragment } from 'react';
import { IIconDropdownProps, IIconDropdownState } from './models';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import Button from './styledComponents/Button';
import ButtonLabel from './styledComponents/ButtonLabel';
import ButtonToggle from './styledComponents/ButtonToggle';
import Menu from './styledComponents/Menu';
import List from './styledComponents/List';
import ListItem from './styledComponents/ListItem';
import Text from './styledComponents/Text';
import Icon from './styledComponents/Icon';
import Translate from '@kurtosys/ksys-app-components/dist/components/base/Translate';
import ClickLayer from '@kurtosys/ksys-app-components/dist/components/base/ClickLayer';

@inject('appStore', 'iconDropdownStore')
@observer
export class IconDropdown extends Component<IIconDropdownProps, IIconDropdownState> {
	static configurationKey: 'iconDropdown' = 'iconDropdown';
	static styleKey: 'iconDropdown' = 'iconDropdown';

	@observable isOpen = false;

	toggleOpen = () => {
		this.isOpen = !this.isOpen;
	}
	setSelectedItem = (value: any) => {
		if (this.props.onSelectItem) {
			this.props.onSelectItem(value);
		}
		this.isOpen = false;
	}
	onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		if (this.props.onChange) {
			this.props.onChange(event);
		}
		this.isOpen = false;
	}

	constructor(props: IIconDropdownProps) {
		super(props);
	}

	render() {
		const {
			className,
			iconDropdownStore,
			options = [],
			value,
			field,
		} = this.props;

		if (!iconDropdownStore) {
			return null;
		}

		const selectedItem = options.find(option => option.value === value);
		const { iconAlignment } = iconDropdownStore;
		const placeholder = iconDropdownStore.getPlaceholderText(field);
		return (
			<Wrapper className={ className }>
				<Button onClick={ this.toggleOpen }>
					<ButtonLabel>
						{ selectedItem && selectedItem.icon && (
							<Icon
								src={ selectedItem.icon }
								align={ iconAlignment }
							/>
						) }
						<Text>
							<Translate>
								{ selectedItem ? selectedItem.key : placeholder }
							</Translate>
						</Text>
					</ButtonLabel>
					<ButtonToggle isOpen={ this.isOpen } />
				</Button>
				{ this.isOpen && (
					<React.Fragment>
						<ClickLayer onClick={ this.toggleOpen } />
						<Menu>
							<List>
								<ListItem
									onClick={ () => {
										this.setSelectedItem(null);
									} }
								>
									<Text>{ placeholder }</Text>
								</ListItem>
								{ options.map((item, i) => {
									return (
										<ListItem
											key={ item.key }
											selected={ item.value === value }
											onClick={ () => {
												this.setSelectedItem(item.value);
											} }
										>
											{ item.icon && (
												<Icon
													src={ item.icon }
													align={ iconAlignment }
												/>
											) }
											<Text>{ item.key }</Text>
										</ListItem>
									);
								}) }
							</List>
						</Menu>
					</React.Fragment>
				) }
				<select
					value={ value || undefined }
					onChange={ (event) => {
						this.onChange(event);
					} }
					hidden
				>
					<option />
					{ options.map((option) => {
						return (
							<option key={ option.key } value={ option.value }>
								{ option.value }
							</option>
						);
					}) }
				</select>
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(IconDropdown, 'iconDropdown');
