import * as React from 'react';
import Translate from '@kurtosys/ksys-app-components/dist/components/base/Translate';
import { InjectedStyledComponent } from '../../shared/InjectedStyledComponent';

const cssPrefix = `
	width: 100%;
	text-align: left;
`;

const text = (props: any) => {
	const { className, children = '' } = props;
	return (
		<span className={ className }>
			<Translate>
				{ children }
			</Translate>
		</span>
	);
};

export default InjectedStyledComponent(text, 'iconDropdown', ['text'], ['appStore'], cssPrefix);