import * as React from 'react';
import Translate from '@kurtosys/ksys-app-components/dist/components/base/Translate';
import { InjectedStyledComponent } from '../../shared/InjectedStyledComponent';

const Subtitle = (props: any) => {
	const { className, text = '' } = props;
	return (
		<h2 className={ className }>
			<Translate>
				{ text }
			</Translate>
		</h2>
	);
};

export default InjectedStyledComponent(Subtitle, 'header', ['subtitle']);