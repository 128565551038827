import { utils } from '@kurtosys/ksys-app-template';
import { computed, action } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { ILinksConfiguration } from '../models';
import { ILinkProps } from '../models/ILinkProps';
import { getSelectionsForRedirect } from '../../../utils/getSelectionsForRedirect';

export class LinksStore extends StoreBase {
	static componentKey: 'links' = 'links';

	@computed
	get configuration(): ILinksConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(LinksStore.componentKey);
		}
	}

	@action
	async initialize(): Promise<void> {

	}

	@computed
	get show(): boolean {
		const display = this.configuration && this.configuration.display || 'always';
		if (display === 'has-all-selections') {
			return this.storeContext &&
				this.storeContext.selectionStore &&
				this.storeContext.selectionStore.hasAllSelections;
		}
		return true;
	}

	@computed
	get links(): ILinkProps[] {
		const { appStore } = this.storeContext;
		const linksConfig = this.configuration && this.configuration.links;

		if (!utils.typeChecks.isNullOrEmpty(linksConfig)) {
			return linksConfig.map((linkConfig) => {
				const { text, target, redirect } = linkConfig;
				const appParamsHelper = this.storeContext.appStore.appParamsHelper;
				const selections = getSelectionsForRedirect(appParamsHelper, this.storeContext.selectionStore.rawValues);
				const href = appStore.redirectHelper.getRedirectPath(redirect, selections) || '';
				return { text, target, href };
			});
		}

		return [];
	}
}