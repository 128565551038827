import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { IAppStoreInjection } from '../../../models/app/IAppStoreInjection';
import { IImage } from '../../../models/app/IImage';
import { InjectedStyledComponent } from '../InjectedStyledComponent';

export interface ImageProps extends IImage, IAppStoreInjection { }

@inject('appStore')
class Image extends Component<ImageProps> {
	static styleKey: 'image' = 'image';
	constructor(props: ImageProps) {
		super(props);
	}

	render() {
		const {
			appStore,
			className,
			src,
			prefixBaseUrl = true,
			// tslint:disable-next-line
			...props
		} = this.props;
		return (
			<img
				className={ className }
				src={
					appStore && prefixBaseUrl
						? `${ appStore.assetsBaseUrl }${ src }`
						: `${ src }`
				}
				{ ...props }
			/>
		);
	}
}

export default InjectedStyledComponent(Image, 'shared', ['image']);
