import React, { Component } from 'react';
import {
	IDisclaimerProps,
	IDisclaimerState,
} from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Heading from './styledComponents/Heading';
import List from './styledComponents/List';
import ListItem from './styledComponents/ListItem';
import Content from './styledComponents/Content';
import { IDisclaimerContent } from './models/IDisclaimerContent';
import Translate from '@kurtosys/ksys-app-components/dist/components/base/Translate';
import Wrapper from './styledComponents/Wrapper';

@inject('appStore', 'disclaimerStore')
@observer
export class Disclaimer extends Component<IDisclaimerProps, IDisclaimerState> {
	static configurationKey: 'disclaimer' = 'disclaimer';
	static styleKey: 'disclaimer' = 'disclaimer';
	constructor(props: IDisclaimerProps) {
		super(props);
	}
	render() {
		const { className, disclaimerStore, appStore } = this.props;
		const disclaimers = (disclaimerStore && disclaimerStore.disclaimers) || [];
		const heading = (disclaimerStore && disclaimerStore.heading) || '';

		if (!appStore || !appStore.hasSelectionsAndDisclaimers) {
			return null;
		}
		return (
			<Wrapper className={ className }>
				<Heading>
					<Translate>{ heading }</Translate>
				</Heading>
				<List>
					{ disclaimers.map((disclaimer: IDisclaimerContent, index) => (
						<ListItem key={ index }>
							<Content dangerouslySetInnerHTML={ { __html: disclaimer.content } } />
						</ListItem>
					)) }
				</List>
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(Disclaimer, 'disclaimer');

