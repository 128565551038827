import React, { Component } from 'react';
import {
	ISelectionWizardProps,
	ISelectionWizardState,
} from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import Navigator from './styledComponents/Navigator';
import NavigatorItem from './styledComponents/NavigatorItem';
import NavigatorItemChevron from './styledComponents/NavigatorItemChevron';
import ActiveNavigatorItem from './styledComponents/ActiveNavigatorItem';
import DisabledNavigatorItem from './styledComponents/DisabledNavigatorItem';
import Content from './styledComponents/Content';
import Options from './styledComponents/Options';
import Option from './styledComponents/Option';
import Chevron from './styledComponents/Chevron';
import Confirmation from './styledComponents/Confirmation';
import { utils } from '@kurtosys/ksys-app-template';
import Translate from '@kurtosys/ksys-app-components/dist/components/base/Translate';
import Disclaimer from '../Disclaimer/Disclaimer';
import Acceptance from '../Acceptance/Acceptance';
import CallToAction from '../CallToAction/CallToAction';
import Footnote from '../Footnote/Footnote';
import Links from '../Links/Links';
import Text from '@kurtosys/ksys-app-components/dist/components/base/Text';

const isNullOrUndefined = utils.typeChecks.isNullOrUndefined;

@inject('appStore', 'selectionWizardStore', 'selectionStore', 'translationStore')
@observer
export class SelectionWizard extends Component<ISelectionWizardProps, ISelectionWizardState> {
	static configurationKey: 'selectionWizard' = 'selectionWizard';
	static styleKey: 'selectionWizard' = 'selectionWizard';
	constructor(props: ISelectionWizardProps) {
		super(props);
	}
	render() {
		const { className, selectionWizardStore, selectionStore, translationStore } = this.props;

		if (!selectionWizardStore || !selectionStore || !translationStore) {
			return null;
		}
		const {
			navigatorFields,
			options,
			handleOptionSelect,
			handleNavigatorItemSelect,
			selectedField,
			selectedFieldKey,
		} = selectionWizardStore;

		if (selectedField) {
			const { autoSelectSingleOption } = selectedField;
			if (autoSelectSingleOption && !isNullOrUndefined(options)) {
				const autoSelectOptions = options.filter(option => !isNullOrUndefined(option.value));
				if (autoSelectOptions.length === 1) {
					handleOptionSelect(autoSelectOptions[0].value);
				}
			}
		}

		return (

			<Wrapper className={ className }>
				<Navigator>
					{ (navigatorFields || []).map((navigatorField) => {
						const { label, key, disabled = false } = navigatorField;
						const valueLabel = selectionStore.getLabel(navigatorField);
						const rawDisplayText = (utils.typeChecks.isNullOrUndefined(valueLabel) ? label : valueLabel) || key;
						const displayText = translationStore.translate(rawDisplayText);

						const Component = navigatorField.key === selectedFieldKey ? ActiveNavigatorItem : (disabled ? DisabledNavigatorItem : NavigatorItem);
						const onClick = !disabled ? handleNavigatorItemSelect : (() => { });
						return (
							<Component key={ key } onClick={ () => onClick(navigatorField) } >
								<span dangerouslySetInnerHTML={ { __html: displayText } }></span>
								{ !disabled && <NavigatorItemChevron /> }
							</Component>
						);
					}) }
				</Navigator>
				<Content>
					{ !selectionStore.hasAllSelections && selectedField && (
						<Options>
							{ (options || []).map((option) => {
								const { key, value } = option;
								const descriptionTextProps = selectionStore.getDescriptionTextProps(option, selectedField);
								return (
									<Option onClick={ () => handleOptionSelect(value) } key={ value }>

										<Translate>{ key }</Translate>
										<Chevron />
										{ descriptionTextProps && <Text { ...descriptionTextProps } /> }
									</Option>
								);
							}) }
						</Options>
					) }
					{
						selectionStore.hasAllSelections && (
							<Confirmation>
								<Disclaimer />
								<Links />
								<Acceptance />
								<CallToAction />
							</Confirmation>
						)
					}
					<Footnote />
				</Content>
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(SelectionWizard, 'selectionWizard');

