import { StoreContext } from '../../../configuration/StoreContext';
import { computed } from 'mobx';
import Title from '../styledComponents/Title';
import Subtitle from '../styledComponents/Subtitle';
import Logo from '../styledComponents/Logo';
import { IHeaderComponents } from '../models/IHeaderComponents';
import { IGrid } from '@kurtosys/ksys-app-components/dist/components/base/Grid/models/IGrid';
import { IHeaderConfiguration } from '../models';

export class HeaderStore {
	static componentKey: 'header' = 'header';
	storeContext: StoreContext;
	constructor(storeContext: StoreContext) {
		this.storeContext = storeContext;
	}

	getHeaderConfiguration = () => {
		return this.storeContext.appStore.getComponentConfiguration('header');
	}
	@computed
	get configuration(): IHeaderConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(HeaderStore.componentKey);
		}
	}

	@computed
	get title() {
		const headerConfiguration = this.getHeaderConfiguration();
		return headerConfiguration && headerConfiguration.title;
	}

	@computed
	get subtitle() {
		const headerConfiguration = this.getHeaderConfiguration();
		return headerConfiguration && headerConfiguration.subtitle;
	}

	@computed
	get logo() {
		const headerConfiguration = this.getHeaderConfiguration();
		return headerConfiguration && headerConfiguration.logo;
	}
	@computed
	get components(): IHeaderComponents {
		return {
			title: {
				key: 'title',
				component: Title,
				hide: !this.title,
				props: {
					culture: this.storeContext.translationStore.culture,
					text: this.title && this.title.text,
				},
			},
			subtitle: {
				key: 'subtitle',
				component: Subtitle,
				hide: !this.subtitle,
				props: {
					culture: this.storeContext.translationStore.culture,
					text: this.subtitle && this.subtitle.text,
				},
			},
			logo: {
				key: 'logo',
				component: Logo,
				hide: !this.logo,
				props: {
					culture: this.storeContext.translationStore.culture,
					src: this.logo && this.logo.src,
					title: this.logo && this.logo.alternativeText,
					alt: this.logo && this.logo.alternativeText,
				},
			},
		};
	}


	@computed
	get grid(): IGrid | undefined {
		if (this.configuration && this.configuration.grid) {
			return this.configuration.grid;
		}
	}
}