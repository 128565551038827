import * as React from 'react';
import Translate from '@kurtosys/ksys-app-components/dist/components/base/Translate';
import { InjectedStyledComponent } from '../../shared/InjectedStyledComponent';
import { withSelector } from '../../shared/withSelector';

const Title = (props: any) => {
	const { className, text = '' } = props;
	const H1 = withSelector('h1', props);
	return (
		<H1 id="ksys-attestation-title" className={ className }>
			<Translate>{ text }</Translate>
		</H1>
	);
};

export default InjectedStyledComponent(Title, 'header', ['title']);
