import * as React from 'react';
import styled, { keyframes } from 'styled-components';

const FileDocumentBoxIcon = ({ className = '' }) => (
	<svg viewBox="0 0 24 24" className={ className } fill="currentColor">
		<path d="M14,17H7V15H14M17,13H7V11H17M17,9H7V7H17M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3Z" />
	</svg>
);

const pulse = keyframes`
    from {
      transform: scale3d(1, 1, 1);
    }
  
    50% {
      transform: scale3d(1.05, 1.05, 1.05);
    }
  
    to {
      transform: scale3d(1, 1, 1);
    }
`;

const LoadingIcon = styled<any>(FileDocumentBoxIcon)`
    display: block;
    height: 48px;
    width: 48px;
    margin: 48px auto;
    color: #adb5bd;
    animation: ${pulse } 1s infinite;
`;

export const Loading = () => <LoadingIcon />;

export default Loading;
