import * as React from 'react';

export const withSelector = (WrappedComponent: any, propsOrSelector: string | any) => {
	return (props: any) => {
		let selector = propsOrSelector;
		if (typeof propsOrSelector === 'object') {
			selector = propsOrSelector.selector || propsOrSelector['data-selector'];
		}
		return <WrappedComponent data-selector={ selector } { ...props } />;
	};
};