import { stores } from '@kurtosys/ksys-app-template';
export const GLOBAL_DISCLAIMER_STORE_WINDOW_KEY = '__ksysGlobalDisclaimerStore__';

export function getGlobalDisclaimerStore(): stores.GlobalDisclaimerStore | undefined {
	return (window as any)[GLOBAL_DISCLAIMER_STORE_WINDOW_KEY];
}
export function initializeGlobalDisclaimers() {
	const globalDisclaimerStore = getGlobalDisclaimerStore();
	if (!globalDisclaimerStore) {
		(window as any)[GLOBAL_DISCLAIMER_STORE_WINDOW_KEY] = new stores.GlobalDisclaimerStore();
	}
}
