import { computed, action } from 'mobx';
import { StoreContext } from '../../../configuration/StoreContext';
import { ICallToActionConfiguration } from '../models';
import { TCallToActionOrder } from '../models/TCallToActionOrder';

export class CallToActionStore {
	static componentKey: 'callToAction' = 'callToAction';
	storeContext: StoreContext;
	constructor(storeContext: StoreContext) {
		this.storeContext = storeContext;
	}
	@computed
	get configuration(): ICallToActionConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(CallToActionStore.componentKey);
		}
	}
	@computed
	get disabled(): boolean {
		if (this.storeContext && this.storeContext.acceptanceStore) {
			const { accepted, mode } = this.storeContext.acceptanceStore;
			return mode !== 'IMPLICIT' && mode !== 'STORAGE' && !accepted;
		}
		return true;

	}
	@computed
	get text(): string {
		return (this.configuration && this.configuration.text) || 'Accept';
	}
	@computed
	get rejectText(): string | undefined {
		return (this.configuration && this.configuration.rejectText);
	}
	@computed
	get order(): TCallToActionOrder {
		return (this.configuration && this.configuration.order) || 'acceptThenReject';
	}
	@computed
	get hint(): string | undefined {
		return (this.configuration && this.configuration.hint);
	}
	@action
	handleClick = () => {
		if (this.storeContext && this.storeContext.appStore) {
			this.storeContext.appStore.setAttestationAndRedirect();
		}
	}
	@action
	handleRejectClick = () => {
		if (this.storeContext && this.storeContext.appStore) {
			this.storeContext.appStore.rejectAndRedirect();
		}
	}

}