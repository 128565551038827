import React, { Component } from 'react';
import {
	IAcceptanceProps,
	IAcceptanceState,
} from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import Checkbox from './styledComponents/Checkbox';
import Text from './styledComponents/Text';
import { isNullOrUndefined } from 'util';

@inject('appStore', 'acceptanceStore')
@observer
export class Acceptance extends Component<IAcceptanceProps, IAcceptanceState> {
	static configurationKey: 'acceptance' = 'acceptance';
	static styleKey: 'acceptance' = 'acceptance';
	constructor(props: IAcceptanceProps) {
		super(props);
	}
	handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.checked;
		const { acceptanceStore } = this.props;
		if (acceptanceStore) {
			acceptanceStore.singleAccepted = value;
		}
	}
	render() {
		const { acceptanceStore, appStore } = this.props;
		if (!acceptanceStore || !appStore || !appStore.hasSelectionsAndDisclaimers) {
			return null;
		}
		const { mode, text, items, textProps } = acceptanceStore;

		if (mode === 'IMPLICIT') {
			return <Wrapper>
				{ !isNullOrUndefined(text) && <Text { ...textProps } value={ text } /> }
			</Wrapper>;

		}

		if (mode === 'MULTIPLE') {
			return <Wrapper>
				{
					items.map((item: any) => {
						const { key, text } = item;
						const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
							const value = event.target.checked;
							const { acceptanceStore } = this.props;
							if (acceptanceStore) {
								acceptanceStore.toggleItem(key, value);
							}
						};

						return (
							<Checkbox
								key={ key }
								label={ text }
								onChange={ onChange }
							/>
						);
					})
				}
			</Wrapper>;
		}

		return (
			<Wrapper>
				<Checkbox
					label={ text }
					onChange={ this.handleChange }
				/>
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(Acceptance, 'acceptance');

