import { computed, observable } from 'mobx';
import { StoreContext } from '../../../configuration/StoreContext';
import { IIconDropdownConfiguration } from '../models';
import { ISelectionField } from '../../Selection/models/ISelectionField';

export class IconDropdownStore {
	static componentKey: 'iconDropdown' = 'iconDropdown';
	storeContext: StoreContext;
	constructor(storeContext: StoreContext) {
		this.storeContext = storeContext;
	}
	@computed
	get configuration(): IIconDropdownConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(IconDropdownStore.componentKey);
		}
	}

	@computed
	get iconAlignment() {
		return this.configuration && this.configuration.icon && this.configuration.icon.align;
	}

	getPlaceholderText(field: ISelectionField) {
		const { translationStore } = this.storeContext;
		const text = field.placeholderText || (this.configuration && this.configuration.placeholderText) || '\u00a0';
		if (translationStore) {
			return translationStore.translate(text);
		}
		return text;
	}


}