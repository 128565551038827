import React, { Component } from 'react';
import { ISelectionFieldProps, ISelectionFieldState } from './models';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Translate from '@kurtosys/ksys-app-components/dist/components/base/Translate';
import Wrapper from './styledComponents/Wrapper';
import Label from './styledComponents/Label';
import Select from './styledComponents/Select';
import Option from './styledComponents/Option';
import List from './styledComponents/List';
import ListItem from './styledComponents/ListItem';
import { IconDropdown } from '../IconDropdown/IconDropdown';
import { SelectionFieldDisplayLogic } from '../../models/app/SelectionFieldDisplayLogic';
import { utils } from '@kurtosys/ksys-app-template';
import Text from '@kurtosys/ksys-app-components/dist/components/base/Text';

const isNullOrUndefined = utils.typeChecks.isNullOrUndefined;

@inject('appStore', 'selectionFieldStore', 'translationStore')
@observer
export class SelectionField extends Component<
ISelectionFieldProps,
ISelectionFieldState
> {
	static configurationKey: 'selectionField' = 'selectionField';
	static styleKey: 'selectionField' = 'selectionField';

	constructor(props: ISelectionFieldProps) {
		super(props);
		const { field } = props;
		const { type, defaultValue } = field;
		if (type === 'EMBEDDED_INPUT') {
			const value = props.value || defaultValue;
			this.handleChange(value);
		}
	}

	@action
	handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const value = event.target.value;
		this.handleChange(value);
	}

	@action
	handleItemClick = (value: any) => {
		this.handleChange(value);
	}

	@action
	handleChange = (value: any) => {
		const { onChange, field, selectionFieldStore } = this.props;
		const { onChange: fieldOnChange } = field;

		if (onChange) {
			onChange(field, value);
		}
		// This must go after the onChange from the props
		if (fieldOnChange && selectionFieldStore) {
			selectionFieldStore.handleFieldOnChange(field, value);
		}
		// this.value = value;
	}

	render() {
		const { field, options, value, translationStore, selectionStore } = this.props;
		const { label, type, autoSelectSingleOption, display = SelectionFieldDisplayLogic.show } = field;

		if (autoSelectSingleOption && isNullOrUndefined(value) && !isNullOrUndefined(options)) {
			const autoSelectOptions = options.filter(option => !isNullOrUndefined(option.value));
			if (autoSelectOptions.length === 1) {
				this.handleChange(autoSelectOptions[0].value);
			}
		}
		if (display === SelectionFieldDisplayLogic.showIfMoreThanOneOption && (!options || options.length <= 1)) {
			return null;
		}
		return (
			<Wrapper>
				<Label>
					<Translate>{ label }</Translate>
				</Label>
				{ type === 'ICON_DROP_DOWN' && (
					<IconDropdown
						field={ field }
						value={ value }
						options={ options }
						onSelectItem={ this.handleItemClick }
						onChange={ this.handleSelectChange }
					/>
				) }
				{ type === 'DROP_DOWN' && (
					<Select value={ value } onChange={ this.handleSelectChange }>
						{ options.map((option) => {
							return (
								<Option key={ option.value } value={ option.value }>
									{ translationStore ? translationStore.translate(option.key) : option.key }
								</Option>
							);
						}) }
					</Select>
				) }
				{ type === 'LIST' && (
					<List>
						{ options.map((option) => {
							const listItemProps = {
								key: option.value,
								onClick: () =>
									this.handleItemClick(option.value),
								selected: value === option.value,
							};
							const descriptionTextProps = selectionStore && selectionStore.getDescriptionTextProps(option, field);
							return <ListItem { ...listItemProps }>
								<Translate>{ option.key }</Translate>
								{ descriptionTextProps && <Text { ...descriptionTextProps } /> }
							</ListItem>;
						}) }
					</List>
				) }
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(SelectionField, 'selectionField');
