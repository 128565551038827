import { utils } from '@kurtosys/ksys-app-template';
import styled from 'styled-components';
import { withSelector } from '@kurtosys/ksys-app-components/dist/shared/withSelector';
import { inject, observer } from 'mobx-react';
import { IAppStoreInjection } from '../../models/app/IAppStoreInjection';
import { IComponentStyles } from '../../models/app/IComponentStyles';

const getCss = utils.componentStyle.getCss;

// tslint:disable-next-line: function-name
export function InjectedStyledComponent<K extends keyof IComponentStyles>(
	componentOrElement: any,
	componentStyleKey: K,
	childStyleKeys?: string[],
	storeKeys: string[] = ['appStore'],
	rawCssPrefix?: string,
	rawCssSuffix?: string,
) {
	return (
		inject(...storeKeys)(
			observer(
				withSelector
					(
						styled(componentOrElement)`
					${rawCssPrefix || '' }
					${
							(props: IAppStoreInjection) => {
								const { appStore } = props;
								const componentStyle = appStore && appStore.getComponentStyle(componentStyleKey, ...(childStyleKeys || []));
								return getCss(componentStyle, props);
							}
							}
					${rawCssSuffix || '' }
				`, [componentStyleKey, ...(childStyleKeys || [])].join('-')),
			),
		)
	);
}
