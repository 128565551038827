import { stores } from '@kurtosys/ksys-app-template';
export const GLOBAL_EVENT_STORE_WINDOW_KEY = '__ksysGlobalEventStore__';

export function getGlobalEventStore(): stores.GlobalEventStore | undefined {
	return (window as any)[GLOBAL_EVENT_STORE_WINDOW_KEY];
}
export function initializeGlobalEvents() {
	const globalEventStore = getGlobalEventStore();
	if (!globalEventStore) {
		(window as any)[GLOBAL_EVENT_STORE_WINDOW_KEY] = new stores.GlobalEventStore();
	}
}
