import { StoreContext } from '../../../configuration/StoreContext';
import { ISelectionFieldBase } from '../../../models/app/ISelectionFieldBase';
import { ISelectionValues } from '../models/ISelectionValues';
import { observable, computed, toJS, reaction } from 'mobx';
import { IKeyValuePair } from '../../../models/commonTypes';
import { helpers, utils } from '@kurtosys/ksys-app-template';
import { ISelectionConfiguration } from '../models';
import { ISelectionOption } from '../../../models/app/ISelectionOption';
import { TranslationStore } from '../../App/stores/TranslationStore';
import { ISelectionFieldOption } from '../../../models/app/ISelectionFieldOption';
import { TSelectionMode } from '../models/TSelectionMode';
import { IGeolocation } from '../models/IGeolocation';
import { ITextProps } from '@kurtosys/ksys-app-components/dist/components/base/Text/models';

export class SelectionStore {
	static componentKey: 'selection' = 'selection';
	storeContext: StoreContext;
	constructor(storeContext: StoreContext) {
		this.storeContext = storeContext;
	}
	@observable.ref values: ISelectionValues = {};
	@computed
	get rawValues(): ISelectionValues {
		return this.values;
	}

	get translationStore(): TranslationStore {
		return this.storeContext.translationStore;
	}
	getConditionalValueFieldValue = (field: ISelectionFieldBase, values?: any, explicitDefaultValue?: string) => {
		const { options, defaultValue } = field;
		let response: any = defaultValue;
		let selectedOption: ISelectionFieldOption | undefined;
		if (options) {
			for (const option of options) {
				const { conditional } = option;
				if (conditional) {
					const conditionalHelper = new helpers.ConditionalHelper(conditional);
					if (conditionalHelper.matchesWithOptions({ instance: values, executionOptions: this.storeContext.queryStore.executionOptions })) {
						selectedOption = option;
						break;
					}
				}
				else {
					selectedOption = option;
					break;
				}
			}
		}
		if (selectedOption) {
			const { value, allowedExplicitDefaultValues } = selectedOption;
			response = value;
			if (response === '{useExplicitDefaultValue}') {
				if (
					!utils.typeChecks.isUndefined(explicitDefaultValue) &&
					(!allowedExplicitDefaultValues || allowedExplicitDefaultValues.includes(explicitDefaultValue))
				) {
					response = explicitDefaultValue;
				}
				else {
					response = defaultValue;
				}
			}
		}
		return response;
	}
	getSelectedFieldOption = (field: ISelectionFieldBase, options?: IKeyValuePair[], values?: any): IKeyValuePair => {
		const { key, type, defaultValue } = field;
		const response: IKeyValuePair = { key, category: undefined, value: undefined };
		const inputs = this.storeContext.appStore.appParamsHelper.inputs;
		if (!values) {
			values = this.values;
		}

		if (type === 'EMBEDDED_INPUT') {
			const inputValue = inputs && inputs[key];
			response.value = inputValue || defaultValue;
			return response;
		}
		if (type === 'CONDITIONAL_VALUE') {
			const explicitDefaultValue = inputs && inputs[key];
			response.value = this.getConditionalValueFieldValue(field, values, explicitDefaultValue);
			return response;
		}

		const value = values[key];
		const option = this.getOption(field, value, values, options);
		if (option) {
			response.value = value;
			response.category = option.category;
			return response;
		}
		return response;
	}
	getLabel = (field: ISelectionFieldBase, options?: IKeyValuePair[], values?: any) => {
		if (!options) {
			options = this.getOptions(field, values);
		}
		if (!values) {
			values = this.values;
		}
		const { key } = field;
		const value = values[key];
		const option = this.findValueInOptions(value, options);
		if (option) {
			return option.key;
		}
		return value;
	}
	setValue = (field: ISelectionFieldBase, value: any, options?: IKeyValuePair[]) => {
		const { key, onChange } = field;
		const option = this.getOption(field, value);
		const labelKey = `${ key }_label`;
		const categoryKey = `${ key }_category`;
		const values = Object.assign({}, {
			...this.values,
			[key]: value,
			[labelKey]: option && option.key,
			[categoryKey]: option && option.category,
		});
		const cleanValues = this.cleanValues(values, options);
		this.values = cleanValues;
		if (onChange) {
			const fieldResponse = this.getSelectedFieldOption(field);
			this.storeContext.selectionFieldStore.handleFieldOnChange(field, fieldResponse.value);
		}
	}

	@computed
	get hasAllSelections() {
		const values = this.rawValues;
		const fields = this.fields;
		let hasAllSelections = true;
		for (const field of fields) {
			const { key } = field;
			const value = values[key];
			if (utils.typeChecks.isNullOrUndefined(value)) {
				hasAllSelections = false;
				break;
			}
		}
		return hasAllSelections;
	}
	cleanValues = (values: any, options?: IKeyValuePair[]) => {

		let hasChange = true;
		const fields = this.fields;
		while (hasChange) {
			hasChange = false;
			let mustClear = false;
			for (const field of fields) {
				if (this.mode === 'default') {
					mustClear = false;
				}
				const { key } = field;
				const fieldResponse = this.getSelectedFieldOption(field, options, values);
				let value = fieldResponse.value;
				if (fieldResponse.category) {
					values[`${ key }_category`] = fieldResponse.category;
				}
				if (!mustClear && utils.typeChecks.isNullOrUndefined(value)) {
					mustClear = true;
				}
				if (mustClear) {
					value = undefined;
				}
				if (value && key.endsWith('_label')) {
					value = this.translationStore.translate(value);
				}
				if (value !== values[key]) {
					values[key] = value;
					hasChange = true;
				}
			}
		}
		return values;
	}
	getOption = (field: ISelectionFieldBase, value: any, values?: any, options?: IKeyValuePair[]): IKeyValuePair | undefined => {
		const { key, type, defaultValue } = field;
		if (type === 'EMBEDDED_INPUT') {
			return {
				key,
				value: value || defaultValue,
			} as IKeyValuePair;
		}
		if (!options) {
			options = this.getOptions(field, values);
		}
		// alt values here
		const option = this.findValueInOptions(value, options);
		return option;
	}
	findValueInOptions = (value: any, options: IKeyValuePair[]): IKeyValuePair | undefined => {
		const { appStore } = this.storeContext;
		const translate = appStore.getTranslateFunction();
		const val = options.find((option) => {
			return option.value === value || translate(option.value) === value;
		});
		return val;
	}
	getOptions = (field: ISelectionFieldBase, values?: any): ISelectionOption[] => {
		if (!values) {
			values = this.rawValues;
		}
		const { queryStore } = this.storeContext;
		const options = (field.options || [])
			.filter((option) => {
				const { conditional } = option;
				let response = true;
				if (conditional) {
					const conditionalHelper = new helpers.ConditionalHelper(conditional);
					response = conditionalHelper.matchesWithOptions({
						instance: values,
						executionOptions: this.storeContext.queryStore.executionOptions,
					});
				}
				return response;
			})
			.map((option) => {
				const { label, value, icon, category, description, descriptionTextProps } = option;
				const response: ISelectionOption = {
					value,
					category,
					descriptionTextProps,
					icon: icon && icon.src,
					key: label,
					description: description && queryStore.query(description),
				};
				return response;
			});
		if (field.type === 'DROP_DOWN') {
			options.unshift({
				key: this.getPlaceholderText(field),
				value: null,
			});
		}
		return options;
	}
	getDescriptionTextProps(option: ISelectionOption, field: ISelectionFieldBase): ITextProps | undefined {
		const { description, descriptionTextProps = {} } = option;
		if (description) {
			const { appStore } = this.storeContext;
			const appDescriptionTextProps = (appStore.appComponentConfiguration && appStore.appComponentConfiguration.descriptionTextProps) || {};
			const fieldDescriptionTextProps = (field && field.descriptionTextProps) || {};
			const response: ITextProps = {
				value: description,
			};
			return utils.object.deepMergeObjects(appDescriptionTextProps, fieldDescriptionTextProps, descriptionTextProps, response);
		}
		return undefined;
	}
	getPlaceholderText(field: ISelectionFieldBase) {
		const { translationStore } = this.storeContext;
		const text = field.placeholderText || (this.configuration && this.configuration.placeholderText) || '';
		if (translationStore) {
			return translationStore.translate(text);
		}
		return text;
	}
	@computed
	get configuration(): ISelectionConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(SelectionStore.componentKey);
		}
	}

	@computed
	get geolocationConfig(): IGeolocation {
		const geolocation = this.configuration && this.configuration.geolocation;
		const defaultGeolocation: IGeolocation = {
			enabled: false,
			cascadingFieldValues: [],
		};
		return { ...defaultGeolocation, ...geolocation };
	}

	@observable.ref rawFields: ISelectionFieldBase[] | undefined;
	@computed
	get fields(): ISelectionFieldBase[] {
		if (this.rawFields) {
			return this.rawFields;
		}
		let response: ISelectionFieldBase[] = [];
		if (this.configuration && this.configuration.fields) {
			response = this.configuration.fields;
		}
		response = utils.collection.sortBy(response, field => (field.order || 99999).toString());
		return response;
	}
	set fields(value: ISelectionFieldBase[]) {
		this.rawFields = value;
	}
	@computed
	get mode(): TSelectionMode {
		return (this.configuration && this.configuration.mode) || 'default';
	}
}