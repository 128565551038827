export const alpha3Codes = ['AFG', 'ALB', 'DZA', 'ASM', 'AND', 'AGO', 'AIA', 'ATA', 'ATG', 'ARG', 'ARM', 'ABW', 'AUS', 'AUT', 'AZE', 'BHS', 'BHR', 'BGD', 'BRB', 'BLR', 'BEL', 'BLZ', 'BEN', 'BMU', 'BTN', 'BOL', 'BES', 'BIH', 'BWA', 'BVT', 'BRA', 'IOT', 'BRN', 'BGR', 'BFA', 'BDI', 'CPV', 'KHM', 'CMR', 'CAN', 'CYM', 'CAF', 'TCD', 'CHL', 'CHN', 'CXR', 'CCK', 'COL', 'COM', 'COD', 'COG', 'COK', 'CRI', 'HRV', 'CUB', 'CUW', 'CYP', 'CZE', 'CIV', 'DNK', 'DJI', 'DMA', 'DOM', 'ECU', 'EGY', 'SLV', 'GNQ', 'ERI', 'EST', 'SWZ', 'ETH', 'FLK', 'FRO', 'FJI', 'FIN', 'FRA', 'GUF', 'PYF', 'ATF', 'GAB', 'GMB', 'GEO', 'DEU', 'GHA', 'GIB', 'GRC', 'GRL', 'GRD', 'GLP', 'GUM', 'GTM', 'GGY', 'GIN', 'GNB', 'GUY', 'HTI', 'HMD', 'VAT', 'HND', 'HKG', 'HUN', 'ISL', 'IND', 'IDN', 'IRN', 'IRQ', 'IRL', 'IMN', 'ISR', 'ITA', 'JAM', 'JPN', 'JEY', 'JOR', 'KAZ', 'KEN', 'KIR', 'PRK', 'KOR', 'KWT', 'KGZ', 'LAO', 'LVA', 'LBN', 'LSO', 'LBR', 'LBY', 'LIE', 'LTU', 'LUX', 'MAC', 'MDG', 'MWI', 'MYS', 'MDV', 'MLI', 'MLT', 'MHL', 'MTQ', 'MRT', 'MUS', 'MYT', 'MEX', 'FSM', 'MDA', 'MCO', 'MNG', 'MNE', 'MSR', 'MAR', 'MOZ', 'MMR', 'NAM', 'NRU', 'NPL', 'NLD', 'NCL', 'NZL', 'NIC', 'NER', 'NGA', 'NIU', 'NFK', 'MNP', 'NOR', 'OMN', 'PAK', 'PLW', 'PSE', 'PAN', 'PNG', 'PRY', 'PER', 'PHL', 'PCN', 'POL', 'PRT', 'PRI', 'QAT', 'MKD', 'ROU', 'RUS', 'RWA', 'REU', 'BLM', 'SHN', 'KNA', 'LCA', 'MAF', 'SPM', 'VCT', 'WSM', 'SMR', 'STP', 'SAU', 'SEN', 'SRB', 'SYC', 'SLE', 'SGP', 'SXM', 'SVK', 'SVN', 'SLB', 'SOM', 'ZAF', 'SGS', 'SSD', 'ESP', 'LKA', 'SDN', 'SUR', 'SJM', 'SWE', 'CHE', 'SYR', 'TWN', 'TJK', 'TZA', 'THA', 'TLS', 'TGO', 'TKL', 'TON', 'TTO', 'TUN', 'TUR', 'TKM', 'TCA', 'TUV', 'UGA', 'UKR', 'ARE', 'GBR', 'UMI', 'USA', 'URY', 'UZB', 'VUT', 'VEN', 'VNM', 'VGB', 'VIR', 'WLF', 'ESH', 'YEM', 'ZMB', 'ZWE', 'ALA'];

export const alpha3Map: { [key: string]: string } = {
	AF: 'AFG',
	AL: 'ALB',
	DZ: 'DZA',
	AS: 'ASM',
	AD: 'AND',
	AO: 'AGO',
	AI: 'AIA',
	AQ: 'ATA',
	AG: 'ATG',
	AR: 'ARG',
	AM: 'ARM',
	AW: 'ABW',
	AU: 'AUS',
	AT: 'AUT',
	AZ: 'AZE',
	BS: 'BHS',
	BH: 'BHR',
	BD: 'BGD',
	BB: 'BRB',
	BY: 'BLR',
	BE: 'BEL',
	BZ: 'BLZ',
	BJ: 'BEN',
	BM: 'BMU',
	BT: 'BTN',
	BO: 'BOL',
	BQ: 'BES',
	BA: 'BIH',
	BW: 'BWA',
	BV: 'BVT',
	BR: 'BRA',
	IO: 'IOT',
	BN: 'BRN',
	BG: 'BGR',
	BF: 'BFA',
	BI: 'BDI',
	CV: 'CPV',
	KH: 'KHM',
	CM: 'CMR',
	CA: 'CAN',
	KY: 'CYM',
	CF: 'CAF',
	TD: 'TCD',
	CL: 'CHL',
	CN: 'CHN',
	CX: 'CXR',
	CC: 'CCK',
	CO: 'COL',
	KM: 'COM',
	CD: 'COD',
	CG: 'COG',
	CK: 'COK',
	CR: 'CRI',
	HR: 'HRV',
	CU: 'CUB',
	CW: 'CUW',
	CY: 'CYP',
	CZ: 'CZE',
	CI: 'CIV',
	DK: 'DNK',
	DJ: 'DJI',
	DM: 'DMA',
	DO: 'DOM',
	EC: 'ECU',
	EG: 'EGY',
	SV: 'SLV',
	GQ: 'GNQ',
	ER: 'ERI',
	EE: 'EST',
	SZ: 'SWZ',
	ET: 'ETH',
	FK: 'FLK',
	FO: 'FRO',
	FJ: 'FJI',
	FI: 'FIN',
	FR: 'FRA',
	GF: 'GUF',
	PF: 'PYF',
	TF: 'ATF',
	GA: 'GAB',
	GM: 'GMB',
	GE: 'GEO',
	DE: 'DEU',
	GH: 'GHA',
	GI: 'GIB',
	GR: 'GRC',
	GL: 'GRL',
	GD: 'GRD',
	GP: 'GLP',
	GU: 'GUM',
	GT: 'GTM',
	GG: 'GGY',
	GN: 'GIN',
	GW: 'GNB',
	GY: 'GUY',
	HT: 'HTI',
	HM: 'HMD',
	VA: 'VAT',
	HN: 'HND',
	HK: 'HKG',
	HU: 'HUN',
	IS: 'ISL',
	IN: 'IND',
	ID: 'IDN',
	IR: 'IRN',
	IQ: 'IRQ',
	IE: 'IRL',
	IM: 'IMN',
	IL: 'ISR',
	IT: 'ITA',
	JM: 'JAM',
	JP: 'JPN',
	JE: 'JEY',
	JO: 'JOR',
	KZ: 'KAZ',
	KE: 'KEN',
	KI: 'KIR',
	KP: 'PRK',
	KR: 'KOR',
	KW: 'KWT',
	KG: 'KGZ',
	LA: 'LAO',
	LV: 'LVA',
	LB: 'LBN',
	LS: 'LSO',
	LR: 'LBR',
	LY: 'LBY',
	LI: 'LIE',
	LT: 'LTU',
	LU: 'LUX',
	MO: 'MAC',
	MG: 'MDG',
	MW: 'MWI',
	MY: 'MYS',
	MV: 'MDV',
	ML: 'MLI',
	MT: 'MLT',
	MH: 'MHL',
	MQ: 'MTQ',
	MR: 'MRT',
	MU: 'MUS',
	YT: 'MYT',
	MX: 'MEX',
	FM: 'FSM',
	MD: 'MDA',
	MC: 'MCO',
	MN: 'MNG',
	ME: 'MNE',
	MS: 'MSR',
	MA: 'MAR',
	MZ: 'MOZ',
	MM: 'MMR',
	NA: 'NAM',
	NR: 'NRU',
	NP: 'NPL',
	NL: 'NLD',
	NC: 'NCL',
	NZ: 'NZL',
	NI: 'NIC',
	NE: 'NER',
	NG: 'NGA',
	NU: 'NIU',
	NF: 'NFK',
	MP: 'MNP',
	NO: 'NOR',
	OM: 'OMN',
	PK: 'PAK',
	PW: 'PLW',
	PS: 'PSE',
	PA: 'PAN',
	PG: 'PNG',
	PY: 'PRY',
	PE: 'PER',
	PH: 'PHL',
	PN: 'PCN',
	PL: 'POL',
	PT: 'PRT',
	PR: 'PRI',
	QA: 'QAT',
	MK: 'MKD',
	RO: 'ROU',
	RU: 'RUS',
	RW: 'RWA',
	RE: 'REU',
	BL: 'BLM',
	SH: 'SHN',
	KN: 'KNA',
	LC: 'LCA',
	MF: 'MAF',
	PM: 'SPM',
	VC: 'VCT',
	WS: 'WSM',
	SM: 'SMR',
	ST: 'STP',
	SA: 'SAU',
	SN: 'SEN',
	RS: 'SRB',
	SC: 'SYC',
	SL: 'SLE',
	SG: 'SGP',
	SX: 'SXM',
	SK: 'SVK',
	SI: 'SVN',
	SB: 'SLB',
	SO: 'SOM',
	ZA: 'ZAF',
	GS: 'SGS',
	SS: 'SSD',
	ES: 'ESP',
	LK: 'LKA',
	SD: 'SDN',
	SR: 'SUR',
	SJ: 'SJM',
	SE: 'SWE',
	CH: 'CHE',
	SY: 'SYR',
	TW: 'TWN',
	TJ: 'TJK',
	TZ: 'TZA',
	TH: 'THA',
	TL: 'TLS',
	TG: 'TGO',
	TK: 'TKL',
	TO: 'TON',
	TT: 'TTO',
	TN: 'TUN',
	TR: 'TUR',
	TM: 'TKM',
	TC: 'TCA',
	TV: 'TUV',
	UG: 'UGA',
	UA: 'UKR',
	AE: 'ARE',
	GB: 'GBR',
	UM: 'UMI',
	US: 'USA',
	UY: 'URY',
	UZ: 'UZB',
	VU: 'VUT',
	VE: 'VEN',
	VN: 'VNM',
	VG: 'VGB',
	VI: 'VIR',
	WF: 'WLF',
	EH: 'ESH',
	YE: 'YEM',
	ZM: 'ZMB',
	ZW: 'ZWE',
	AX: 'ALA',
};

/**
 * Return the alpha-3 country code based on the alpha-2 country code
 *
 * @export
 * @param alpha
 * @returns string alpha-3 country code
 */
export function getAlpha3CountryCode(alpha: string): string | undefined {
	if (typeof alpha === 'string') {
		const uppercasedAlpha = alpha.toUpperCase();

		if (alpha3Codes.indexOf(uppercasedAlpha) >= 0) {
			return uppercasedAlpha;
		}

		if (alpha3Map[uppercasedAlpha]) {
			return alpha3Map[uppercasedAlpha];
		}
	}
}