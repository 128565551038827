import { helpers } from '@kurtosys/ksys-app-template';
import { ITranslation, ITranslations } from '../../../models/commonTypes';
import { observable, action, computed } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { getHydrationPoint } from '../../../initialize';

export class TranslationStore extends StoreBase {
	@observable cultureRaw?: string;
	@action
	async initialize(): Promise<void> {

	}

	@observable.ref translationRecords: ITranslation[] = [];
	@computed
	get translationHelper(): helpers.TranslationHelper {
		return new helpers.TranslationHelper(this.targetTranslations, this.fallbackTranslations);
	}

	@computed
	get appConfiguration() {
		return this.storeContext.appStore && this.storeContext.appStore.configuration;
	}

	@computed
	get appParamsHelper() {
		return this.storeContext.appStore && this.storeContext.appStore.appParamsHelper;
	}

	@computed
	get baseCulture() {
		let baseCulture = 'Default';
		const appConfig = this.appConfiguration;
		if (
			appConfig &&
			appConfig.culture &&
			appConfig.culture.base
		) {
			baseCulture = appConfig.culture.base;
		}
		return baseCulture;
	}

	@computed
	get defaultCulture() {
		let defaultCulture = 'en-GB';
		const appConfig = this.appConfiguration;
		if (
			appConfig &&
			appConfig.culture &&
			appConfig.culture.default
		) {
			defaultCulture = appConfig.culture.default;
		}
		return defaultCulture;
	}

	@computed
	get culture() {
		if (this.cultureRaw) {
			return this.cultureRaw;
		}
		let culture = this.defaultCulture;
		const appParams = this.appParamsHelper;
		if (
			appParams &&
			appParams.inputs &&
			appParams.inputs.culture
		) {
			culture = appParams.inputs.culture;
		}
		return culture.split('-').map((segment, index) => index === 0 ? segment.toLowerCase() : segment.toUpperCase()).join('-');
	}

	set culture(value: string) {
		this.cultureRaw = value;
	}

	// Builds a translation dictionary object for the current culture from all translation data. E.g:
	// { 'phrase': 'translation', 'phrase': 'translation', etc }
	@computed
	get targetTranslations() {
		return this.getTranslationsDictionary(this.translationRecords, this.culture);
	}

	@computed
	get fallbackTranslations() {
		return this.getTranslationsDictionary(this.translationRecords, this.defaultCulture);
	}

	getTranslationsDictionary(translationRecords: ITranslation[], targetCulture: string) {
		const response: ITranslations = translationRecords
			.filter((translationRecord) => {
				return (
					translationRecord.translationCulture === targetCulture &&
					translationRecord.culture === this.baseCulture
				);
			})
			.reduce((acc: ITranslations, translationRecord) => {
				const { phrase, translation } = translationRecord;
				acc[phrase] = translation;
				return acc;
			}, {});
		return response;
	}

	@action
	async loadTranslations() {
		if (this.storeContext) {
			const { kurtosysApiStore, appStore } = this.storeContext;
			const { coreConfigurations = {} } = appStore;
			const { hydration = {} } = coreConfigurations;
			const { ignoreTranslations: ignoreTranslationsRaw = false } = hydration;
			const ignoreTranslations = (typeof ignoreTranslationsRaw !== 'boolean' || ignoreTranslationsRaw);
			const hydrationPoint = getHydrationPoint();
			if (!ignoreTranslations && hydrationPoint.translations && hydrationPoint.translations.length > 0) {
				this.translationRecords = hydrationPoint.translations;
			}
			else {
				this.translationRecords = await kurtosysApiStore.listTranslations.execute();
			}
		}
	}

	translate = (key: string, placeholders?: any) => {
		return this.translationHelper.translate(key, placeholders);
	}

	static keys = {
		attestation_acceptance_text: 'attestation_acceptance_text',
		attestation_call_to_action_proceed_text: 'attestation_call_to_action_proceed_text',
		attestation_disclaimer_header: 'attestation_disclaimer_header',
		attestation_footnote: 'attestation_footnote',
		attestation_header_title: 'attestation_header_title',
		attestation_header_subtitle: 'attestation_header_subtitle',
		attestation_selection_field_country_label: 'attestation_selection_field_country_label',
		attestation_selection_field_investorType_label: 'attestation_selection_field_investorType_label',
		attestation_selection_option_country_austria_label: 'attestation_selection_option_country_austria_label',
		attestation_selection_option_country_belgium_label: 'attestation_selection_option_country_belgium_label',
		attestation_selection_option_country_denmark_label: 'attestation_selection_option_country_denmark_label',
		attestation_selection_option_country_finland_label: 'attestation_selection_option_country_finland_label',
		attestation_selection_option_country_france_label: 'attestation_selection_option_country_france_label',
		attestation_selection_option_country_germany_label: 'attestation_selection_option_country_germany_label',
		attestation_selection_option_country_ireland_label: 'attestation_selection_option_country_ireland_label',
		attestation_selection_option_country_italy_label: 'attestation_selection_option_country_italy_label',
		attestation_selection_option_country_luxembourg_label: 'attestation_selection_option_country_luxembourg_label',
		attestation_selection_option_country_netherlands_label: 'attestation_selection_option_country_netherlands_label',
		attestation_selection_option_country_norway_label: 'attestation_selection_option_country_norway_label',
		attestation_selection_option_country_portugal_label: 'attestation_selection_option_country_portugal_label',
		attestation_selection_option_country_spain_label: 'attestation_selection_option_country_spain_label',
		attestation_selection_option_country_sweden_label: 'attestation_selection_option_country_sweden_label',
		attestation_selection_option_country_switzerland_label: 'attestation_selection_option_country_switzerland_label',
		attestation_selection_option_country_uk_label: 'attestation_selection_option_country_uk_label',
		attestation_selection_option_investorType_individual_label: 'attestation_selection_option_investorType_individual_label',
		attestation_selection_option_investorType_institutional_label: 'attestation_selection_option_investorType_institutional_label',
		attestation_selection_option_investorType_intermediary_label: 'attestation_selection_option_investorType_intermediary_label',
	};


}