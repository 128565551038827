import { IStyles } from '../models/app/IStyles';

export const STYLES: IStyles = {
	components: {
		acceptance: {
			children: {
				checkbox: {
					display: 'block',
					width: '100%',
				},
				label: {
					font: {
						size: '13px',
						weight: 'normal',
					},
				},
				wrapper: {
					// display: 'flex',
					margin: {
						bottom: '20px',
						top: '20px',
					},
				},
			},
		},
		app: {
			children: {
				wrapper: {
					color: '#333333',
					font: {
						family: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
						size: '13px',
					},
				},
			},
		},
		links: {
			children: {
				link: {
					display: 'block',
					margin: {
						top: '20px',
					},
				},
			},
		},
		callToAction: {
			children: {
				button: {
					color: '#1C355E',
					font: {
						size: '13px',
						weight: 'bold',
					},
				},
				wrapper: {},
			},
		},
		disclaimer: {
			background: {
				color: '#FFFFFF',
			},
			children: {
				content: {
					overrides: [],
				},
				heading: {
					font: {
						size: '1em',
						weight: 'bold',
					},
					margin: {
						bottom: '1em',
						top: '0',
					},
				},
				list: {
					margin: {
						block: {
							end: '0',
							start: '0',
						},
					},
					padding: {
						inline: {
							start: '0',
						},
					},
				},
				listItem: {},
				wrapper: {},
			},
			font: {
				size: '13px',
			},
			max: {
				height: '270px',
			},
			overflow: {
				_value: 'auto',
			},
			padding: {
				_value: '15px',
			},
		},
		footnote: {
			children: {
				text: {
					margin: {
						top: '20px',
					},
					text: {
						align: 'left',
					},
				},
			},
		},
		header: {
			children: {
				logo: {
					height: '30px',
					margin: {
						_value: '30px',
					},
					width: 'auto',
				},
				subtitle: {
					font: {
						size: '1.2em',
						weight: 'normal',
					},
					margin: {
						bottom: '1em',
						top: '0',
					},
				},
				title: {
					color: '#1C355E',
					font: {
						size: '32px',
					},
					margin: {
						bottom: '10px',
						top: '0',
					},
				},
				wrapper: {},
			},
		},
		iconDropdown: {
			children: {
				button: {},
				buttonLabel: {},
				buttonToggle: {},
				icon: {
					height: '1.2em',
				},
				list: {},
				listItem: {},
				menu: {},
				text: {
					color: 'inherit',
				},
				wrapper: {},
			},
		},
		modal: {
			align: {
				items: 'center',
			},
			background: {
				color: 'rgba(0,0,0,0.5)',
			},
			bottom: '0',
			children: {
				dialog: {
					align: {
						items: 'center',
					},
					background: {
						color: '#f5f5f5',
					},
					border: {
						radius: '3px',
					},
					box: {
						shadow: '0px 0px 10px 0px rgba(0,0,0,0.5)',
					},
					display: 'flex',
					justifyContent: 'center',
					padding: '20px',
					width: '80%',
				},
			},
			display: 'flex',
			justifyContent: 'center',
			left: '0',
			overrides: [
				{
					selector: '> div > section',
					style: {
						width: '100%',
					},
				},
			],
			position: 'fixed',
			right: '0',
			top: '0',
			zIndex: '1000000',
		},
		selection: {
			children: {
				wrapper: {},
			},
			display: 'flex',
			flex: {
				wrap: 'wrap',
			},
			margin: {
				bottom: '1em',
			},
		},
		selectionField: {
			children: {
				label: {
					margin: {
						right: '0.5em',
					},
				},
				list: {
					background: {
						color: 'white',
					},
					border: {
						color: '#cccccc',
						radius: '5px',
						style: 'solid',
						width: '1px',
					},
					height: '5em',
					overflow: {
						y: 'scroll',
					},
					padding: {
						_value: '0',
					},
					width: '200px',
				},
				listItem: {
					border: {
						bottom: {
							_value: '1px solid #cccccc',
						},
					},
					conditionals: [
						{
							conditional: {
								conditions: [
									{
										field: 'selected',
										value: true,
									},
								],
							},
							style: {
								background: {
									color: '#eeeeee',
								},
							},
						},
					],
					cursor: 'pointer',
					padding: {
						_value: '0.25em 0.5em',
					},
				},
				option: {},
				select: {},
				wrapper: {
					align: {
						items: 'center',
					},
					display: 'flex',
					flex: {
						wrap: 'wrap',
					},
					justifyContent: 'center',
					padding: {
						_value: '0.5em',
					},
				},
			},
		},
		selectionWizard: {
			breakpoints: [
				{
					min: '768px',
					style: {
						display: 'flex',
						flex: {
							direction: 'row',
						},
					},
				},
				{
					max: '768px',
					style: {
						display: 'flex',
						flex: {
							direction: 'column',
						},
					},
				},
			],
			children: {
				activeNavigatorItem: {
					background: {
						color: '#FFFFFF',
					},
					border: {
						_value: 'solid 1px #C7C7C7',
						bottom: {
							_value: 'solid 2px #1C355E',
						},
					},
					font: {
						size: '14px',
					},
					padding: {
						_value: '20px',
						left: '15px',
					},
					position: 'relative',
				},
				chevron: {
					border: {
						color: '#1C355E',
						style: 'solid',
						width: '0 3px 3px 0',
					},
					display: 'inline-block',
					height: '10px',
					padding: {
						_value: '0px',
					},
					position: 'absolute',
					right: '15px',
					top: '22px',
					transform: 'rotate(-45deg)',
					width: '10px',
				},
				content: {
					flex: {
						grow: '1',
					},
				},
				navigator: {
					breakpoints: [
						{
							min: '768px',
							style: {
								padding: {
									right: '20px',
								},
								width: '280px',
							},
						},
						{
							max: '768px',
							style: {
								padding: {
									right: '20px',
								},
								width: '100%',
							},
						},
					],
					flex: {
						shrink: '0',
					},
				},
				navigatorItem: {
					background: {
						color: '#FFFFFF',
					},
					border: {
						_value: 'solid 1px #C7C7C7',
					},
					color: '#1C355E',
					cursor: 'pointer',
					font: {
						size: '14px',
						weight: '900',
					},
					margin: {
						bottom: '8px',
					},
					padding: {
						_value: '20px',
						left: '15px',
					},
					position: 'relative',
					selectors: {
						':hover': {
							background: {
								color: '#1C355E',
							},
							color: '#FFFFFF',
							overrides: [
								{
									selector: '[data-selector="selectionWizard-chevron"]',
									style: {
										border: {
											color: '#FFFFFF',
										},
									},
								},
							],
						},
					},
					text: {
						transform: 'uppercase',
					},
				},
				navigatorItemChevron: {
					breakpoints: [
						{
							min: '768px',
							style: {
								border: {
									color: '#1C355E',
									style: 'solid',
									width: '0 3px 3px 0',
								},
								transform: 'rotate(-45deg)',
							},
						},
						{
							max: '768px',
							style: {
								border: {
									color: '#C2C2C2',
									style: 'solid',
									width: '0 1px 1px 0',
								},
								transform: 'rotate(45deg)',
							},
						},
					],
					display: 'inline-block',
					height: '10px',
					padding: {
						_value: '0px',
					},
					position: 'absolute',
					right: '15px',
					top: '22px',
					width: '10px',
				},
				option: {
					border: {
						bottom: {
							_value: 'solid 1px #C2C2C2',
						},
					},
					breakpoints: [
						{
							min: '1099px',
							style: {
								flex: {
									basis: '30%',
								},
								margin: {
									left: '5px',
									right: '5px',
								},
							},
						},
						{
							max: '1099px',
							min: '769px',
							style: {
								flex: {
									basis: '44%',
								},
								margin: {
									left: '5px',
									right: '5px',
								},
							},
						},
						{
							max: '768px',
							style: {
								flex: {
									basis: '100%',
								},
								margin: {
									left: '5px',
									right: '5px',
								},
							},
						},
					],
					cursor: 'pointer',
					font: {
						size: '13px',
					},
					overrides: [
						{
							selector: '[data-selector="selectionWizard-chevron"]',
							style: {
								top: '17px',
							},
						},
					],
					padding: {
						_value: '15px',
						left: '7px',
						right: '7px',
					},
					position: 'relative',
					selectors: {
						':hover': {
							background: {
								color: '#1C355E',
							},
							color: '#FFFFFF',
							overrides: [
								{
									selector: '[data-selector="selectionWizard-chevron"]',
									style: {
										border: {
											color: '#FFFFFF',
										},
									},
								},
							],
						},
					},
					text: {
						transform: 'uppercase',
					},
				},
				options: {
					background: {
						color: '#FFFFFF',
					},
					breakpoints: [
						{
							min: '1099px',
							style: {
								justifyContent: 'initial',
							},
						},
						{
							max: '1099px',
							min: '769px',
							style: {
								justifyContent: 'space-around',
							},
						},
						{
							max: '768px',
							style: {
								justifyContent: 'initial',
							},
						},
					],
					display: 'flex',
					flex: {
						direction: 'row',
						wrap: 'wrap',
					},
					padding: {
						bottom: '6px',
						top: '6px',
					},
				},
			},
		},
		text: {
			children: {
				content: {
					display: 'inline-block',
					overrides: [
						{
							selector: '> *',
							style: {
								display: 'inline-block',
							},
						},
					],
				},
				toggleWrapper: {
					display: 'inline-block',
					overrides: [
						{
							selector: '[data-selector="base-button"]',
							style: {
								color: '#2e91dd',
								cursor: 'pointer',
								outline: {
									_value: 'none',
								},
								border: {
									_value: 'none',
								},
								background: {
									_value: 'none',
								},
							},
						},
					],
				},
			},
		},
		shared: {
			children: {
				dropdownButton: {
					align: {
						items: 'center',
					},
					background: {
						color: 'white',
					},
					border: {
						color: '#cccccc',
						radius: '5px',
						style: 'solid',
						width: '1px',
					},
					display: 'flex',
					font: {
						family: 'inherit',
						size: 'inherit',
					},
					height: '2em',
					padding: {
						_value: '0.25em 0.5em',
					},
					width: '200px',
				},
				dropdownButtonLabel: {
					align: {
						items: 'center',
					},
					display: 'flex',
				},
				dropdownButtonToggle: {
					height: '1.4em',
				},
				dropdownList: {
					margin: {
						_value: '0',
					},
					padding: {
						_value: '0',
					},
				},
				dropdownListItem: {
					conditionals: [
						{
							conditional: {
								conditions: [
									{
										field: 'selected',
										value: true,
									},
								],
							},
							style: {
								background: {
									color: '#eeeeee',
								},
							},
						},
					],
					cursor: 'default',
					display: 'flex',
					justifyContent: 'space-between',
					padding: {
						_value: '0.25em 0.5em',
					},
				},
				dropdownMenu: {
					zIndex: '1000',
					background: {
						color: 'white',
					},
					border: {
						color: '#cccccc',
						radius: '5px',
						style: 'solid',
						width: '1px',
					},
					margin: {
						bottom: '0',
						top: '1px',
					},
					max: {
						height: '300px',
					},
					overflow: {
						_value: 'auto',
					},
					width: '200px',
				},
				image: {},
				listItem: {
					listStyle: {
						type: 'none',
					},
				},
				select: {
					background: {
						color: 'white',
					},
					border: {
						color: '#cccccc',
						radius: '5px',
						style: 'solid',
						width: '1px',
					},
					color: 'inherit',
					font: {
						family: 'inherit',
						size: 'inherit',
					},
					height: '2em',
					width: '200px',
				},
			},
		},
	},
	theme: {
		base: {
			clickLayer: {
				zIndex: '999',
				position: 'fixed',
				top: '0',
				right: '0',
				bottom: '0',
				left: '0',
				background: {
					color: 'transparent',
				},
			},
			button: {
				background: {
					color: 'transparent',
				},
				border: {
					_value: '2px solid #1C355E',
				},
				color: '#1C355E',
				cursor: 'pointer',
				font: {
					size: '13px',
					weight: 'bold',
				},
				height: '44px',
				margin: {
					right: '15px',
				},
				min: {
					width: '160px',
				},
				padding: {
					left: '10px',
					right: '10px',
				},
				selectors: {
					':disabled': {
						border: {
							color: '#8A8A8A',
						},
						color: '#8A8A8A',
						cursor: 'not-allowed',
					},
					':hover': {
						background: {
							color: '#1C355E',
						},
						color: '#FFFFFF',
					},
				},
				text: {
					transform: 'uppercase',
				},
			},
			checkbox: {
				children: {
					icon: {
						verticalAlign: 'bottom',
					},
					label: {
						lineHeight: '24px',
					},
				},
			},
			grid: {
				children: {
					column: {
						display: 'flex',
						flex: {
							direction: 'column',
							grow: '1',
						},
					},
					row: {
						display: 'flex',
						flex: {
							direction: 'row',
						},
					},
				},
			},
		},
	},
};