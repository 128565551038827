import { IConfiguration } from '../models/app/IConfiguration';

export const CONFIGURATION: IConfiguration = {
	allowMultipleAttestations: false,
	assetOverrides: {
		base: {
			checkbox: {
				checked: 'CheckboxMarkedIcon.svg',
				unchecked: 'CheckboxIcon.svg',
			},
		},
		baseUrl: 'https://api-rel.fundpress.io/applicationManager/apps/4d44ec1f-9248-4bae-ac6b-2f612bd73f69/',
	},
	autoAttest: {
		parameter: 'attested',
		performRedirect: true,
	},
	components: {
		acceptance: {
			mode: 'MULTIPLE',
			items: [
				{
					key: 'accept-terms',
					text: 'I Accept the Terms',
					optional: false,
				},
				{
					key: '180-days',
					text: 'Remember me for 180 days',
					optional: true,
				},
			],
			storage: {
				linkedItem: '180-days',
				expiry: {
					period: 'DAYS',
					value: 180,
				},
				type: 'LOCAL',
			},
			text: 'attestation_acceptance_text',
		},
		app: {
			descriptionTextProps: {
				toggleState: 'collapsed',
				collapse: { maxLength: 25, suffix: '' },
				toggleButtons: {
					expand: {
						text: '...',
					},
					collapse: {
						text: 'Read less',
					},
				},


			},
			grid: {
				rows: [
					{
						columns: [
							{
								components: [
									{
										key: 'header',
									},
								],
							},
						],
					},
					{
						columns: [
							{
								components: [
									{
										key: 'selectionWizard',
									},
								],
							},
						],
					},
				],
			},
		},
		callToAction: {
			order: 'acceptThenReject',
			rejectText: 'attestation_call_to_action_reject_text',
			text: 'attestation_call_to_action_proceed_text',
		},
		disclaimer: {
			disclaimers: [
				{
					conditional: {
						conditions: [
							{
								field: 'region',
								key: 'region-is-europe',
								operator: '=',
								value: 'europe',
							},
							{
								field: 'country',
								key: 'country-is-not-swiss',
								operator: '!=',
								value: 'ch',
							},
						],
						key: 'europe_except_swiss',
					},
					type: 'attestation_disclaimer_eu',
				},
				{
					conditional: {
						conditions: [
							{
								field: 'country',
								key: 'country-is-swiss',
								operator: '=',
								value: 'ch',
							},
						],
						key: 'country-is-swiss',
					},
					type: 'attestation_disclaimer_swiss',
				},
				{
					conditional: {
						conditions: [
							{
								field: 'region',
								key: 'region-is-latam',
								operator: '=',
								value: 'latam',
							},
						],
						key: 'region-is-latam',
					},
					type: 'attestation_disclaimer_latam',
				},
				{
					conditional: {
						conditions: [
							{
								field: 'region',
								key: 'region-is-apac',
								operator: '=',
								value: 'apac',
							},
							{
								field: 'country',
								key: 'country-is-sg',
								operator: '=',
								value: 'sg',
							},
						],
						key: 'country-is-sg',
					},
					type: 'attestation_disclaimer_sg',
				},
			],
		},
		footnote: {
			text: {
				type: 'attestation_footnote',
			},
			textProps: {
				hasHtmlContent: true,
			},
		},
		header: {
			grid: {
				rows: [
					{
						columns: [
							{
								components: [
									{
										key: 'title',
									},
								],
							},
						],
					},
				],
			},
			title: {
				text: 'attestation_header_title',
			},
		},
		selection: {
			fields: [],
			geolocation: {
				cascadingFieldValues: [
					{
						conditional: {
							conditions: [
								{
									field: 'country',
									operator: 'in',
									value: [
										'sg',
									],
								},
							],
						},
						key: 'region',
						value: 'apac',
					},
					{
						conditional: {
							conditions: [
								{
									field: 'country',
									operator: 'in',
									value: [
										'at',
										'dk',
										'fi',
										'fr',
										'de',
										'is',
										'ie',
										'it',
										'lu',
										'nl',
										'no',
										'pt',
										'es',
										'se',
										'ch',
										'gb',
									],
								},
							],
						},
						key: 'region',
						value: 'europe',
					},
					{
						conditional: {
							conditions: [
								{
									field: 'region',
									operator: 'in',
									value: [
										'latam',
									],
								},
							],
						},
						key: 'region',
						value: 'latam',
					},
				],
				enabled: true,
				type: 'cloudflare',
			},
			initialValues: 'EMBEDDED_INPUT',
			mode: 'wizard',
		},
		selectionWizard: {
			fields: [
				{
					key: 'region',
					label: 'attestation_selection_field_region_label',
					options: [
						{
							label: 'attestation_selection_option_region_apac_label',
							value: 'apac',
							conditional: {
								conditions: [
									{
										fieldQuery: {
											queryOptionsType: 'url',
											options: {
												url: {
													code: 'pathname',
												},
											},
											transforms: [
												{
													transformOptionsType: 'regex',
													regexTransformOptions: {
														pattern: '/(coco)/?',
														index: 1,
													},
												},
											],
										},
										operator: '!=',
										value: 'coco',
									},
								],
							},
						},
						{
							label: 'attestation_selection_option_region_europe_label',
							value: 'europe',
						},
						{
							label: 'attestation_selection_option_region_latam_label',
							value: 'latam',
						},
					],
				},
				{
					conditional: {
						conditions: [
							{
								field: 'region',
								operator: 'not-in',
								value: [
									'apac',
									'latam',
								],
							},
						],
					},
					key: 'country',
					label: 'attestation_selection_field_country_label',
					options: [
						{
							countryCode: 'AUT',
							label: 'attestation_selection_option_country_at_label',
							value: 'at',
						},
						{
							countryCode: 'DNK',
							label: 'attestation_selection_option_country_dk_label',
							value: 'dk',
						},
						{
							countryCode: 'FIN',
							label: 'attestation_selection_option_country_fi_label',
							value: 'fi',
						},
						{
							countryCode: 'FRA',
							label: 'attestation_selection_option_country_fr_label',
							value: 'fr',
						},
						{
							countryCode: 'DEU',
							label: 'attestation_selection_option_country_de_label',
							value: 'de',
						},
						{
							countryCode: 'ISL',
							label: 'attestation_selection_option_country_is_label',
							value: 'is',
						},
						{
							countryCode: 'IRL',
							label: 'attestation_selection_option_country_ie_label',
							value: 'ie',
						},
						{
							countryCode: 'ITA',
							label: 'attestation_selection_option_country_it_label',
							value: 'it',
						},
						{
							countryCode: 'LUX',
							label: 'attestation_selection_option_country_lu_label',
							value: 'lu',
						},
						{
							countryCode: 'NLD',
							label: 'attestation_selection_option_country_nl_label',
							value: 'nl',
						},
						{
							countryCode: 'NOR',
							label: 'attestation_selection_option_country_no_label',
							value: 'no',
						},
						{
							countryCode: 'PRT',
							label: 'attestation_selection_option_country_pt_label',
							value: 'pt',
						},
						{
							countryCode: 'ESP',
							label: 'attestation_selection_option_country_es_label',
							value: 'es',
						},
						{
							countryCode: 'SWE',
							label: 'attestation_selection_option_country_se_label',
							value: 'se',
						},
						{
							countryCode: 'CHE',
							label: 'attestation_selection_option_country_ch_label',
							value: 'ch',
						},
						{
							countryCode: 'GBR',
							label: 'attestation_selection_option_country_gb_label',
							value: 'gb',
						},
					],
				},
				{
					conditional: {
						conditions: [
							{
								field: 'region',
								operator: 'in',
								value: [
									'apac',
								],
							},
						],
					},
					key: 'country',
					label: 'attestation_selection_field_country_label',
					options: [
						{
							label: 'attestation_selection_option_country_sg_label',
							value: 'sg',
						},
					],
				},
				{
					conditional: {
						conditions: [
							{
								field: 'country',
								operator: 'not-in',
								value: [
									'it',
									'sg',
								],
							},
						],
					},
					key: 'investorType',
					label: 'attestation_selection_field_investorType_label',
					options: [
						{
							category: 'institutional',
							label: 'attestation_selection_option_investorType_consultants_label',
							value: 'consultants',
						},
						{
							category: 'institutional',
							label: 'attestation_selection_option_investorType_familyOffice_label',
							value: 'familyOffice',
						},
						{
							category: 'institutional',
							label: 'attestation_selection_option_investorType_otherInstitutions_label',
							value: 'institutional',
						},
						{
							category: 'institutional',
							label: 'attestation_selection_option_investorType_pensions_label',
							value: 'pensions',
						},
						{
							category: 'institutional',
							label: 'attestation_selection_option_investorType_financialInstitutions_label',
							value: 'financialInstitutions',
						},
						{
							category: 'institutional',
							label: 'attestation_selection_option_investorType_insurance_label',
							value: 'insurance',
						},
						{
							category: 'intermediary',
							label: 'attestation_selection_option_investorType_wealthManagement_label',
							value: 'intermediary',
						},
					],
				},
				{
					conditional: {
						conditions: [
							{
								field: 'country',
								operator: 'in',
								value: [
									'it',
								],
							},
						],
					},
					key: 'investorType',
					label: 'attestation_selection_field_investorType_label',
					options: [
						{
							category: 'intermediary',
							label: 'attestation_selection_option_investorType_it_intermediary_label',
							value: 'consulenti-finanziari',
						},
						{
							category: 'institutional',
							label: 'attestation_selection_option_investorType_it_institutional_label',
							value: 'institutional',
						},
					],
				},
				{
					conditional: {
						conditions: [
							{
								field: 'country',
								operator: 'in',
								value: [
									'sg',
								],
							},
						],
					},
					key: 'investorType',
					label: 'attestation_selection_field_investorType_label',
					options: [
						{
							category: 'institutional',
							label: 'attestation_selection_option_investorType_institutional_label',
							value: 'institutional',
							description: {
								queryOptionsType: 'none',
								value: 'Label for Institutional Investor Type, this should only show a small part until the elipsis is clicked, then it should expand smoothly to display everything.',
							},
							descriptionTextProps: {

							},
						},
						{
							category: 'intermediary',
							label: 'attestation_selection_option_investorType_intermediary_label',
							value: 'intermediary',
						},
						{
							category: 'intermediary',
							label: 'attestation_selection_option_investorType_accreditedInvestor_label',
							value: 'accreditedInvestor',
							description: {
								queryOptionsType: 'none',
								value: 'Label for Accredited Investor Type, this should only show a small part until the elipsis is clicked, then it should expand smoothly to display everything.',
							},
						},
					],
				},
			],
		},
	},
	core: {
		serviceUrl: 'https://api-rel.fundpress.io',
	},
	culture: {
		base: 'Default',
		default: 'en-GB',
	},
	disableAttest: {
		conditional: {
			conditions: [
				{
					fieldQuery: {
						queryOptionsType: 'dynamic',
						options: {
							dynamic: {
								key: 'user-agent',
							},
						},
					},
					operator: 'regex-test',
					value: 'APIs-Google|Mediapartners-Google|AdsBot-Google-Mobile|AdsBot-Google|Googlebot|AdsBot-Google-Mobile-Apps|FeedFetcher-Google|Google-Read-Aloud|DuplexWeb-Google',
				},
			],
		},
		parameter: 'disableAttest',
	},
	name: 'ksys-app-attestation',
	redirect: {
		conditionalRedirects: [
			{
				conditional: {
					conditions: [
						{
							field: 'region',
							value: 'latam',
						},
					],
				},
				url: '/en-{region}/{investorType_category}',
			},
			{
				conditional: {
					conditions: [
						{
							field: 'country',
							value: 'it',
						},
						{
							field: 'investorType_category',
							value: 'intermediary',
						},
					],
				},
				url: '/it-it/{investorType}',
				options: {
					preserveOrigin: true,
					preserveOriginPath: true,
					preserveOriginPathWithOverrides: true,
					preserveOriginQueryString: true,
					preserveOriginHash: true,
					translatePlaceholderValues: true,
				},
			},
		],
		defaultUrl: '/en-{country}/{investorType}/{originPath}',
		options: {
			originPathTransforms: [
				{
					transformOptionsType: 'replace',
					replaceTransformOptions: {
						isRegex: true,
						searchValue: '/[a-z][a-z]-[a-z][a-z]/(intermediary|institutional|consultants)',
						replaceValue: '',
					},
				},
			],
		},
	},
	rejectRedirect: {
		defaultUrl: '/',
		options: {
			preserveOriginPath: false,
		},
	},
	storage: {
		// expiry: {
		// 	period: 'DAYS',
		// 	value: 90,
		// },
		obfuscated: false,
		type: 'SESSION',
	},
	token: '144ef90f-a4f6-46dd-a541-e65deeacaa26',
};

