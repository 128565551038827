import React, { Component } from 'react';
import {
	ISelectionProps,
	ISelectionState,
} from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import SelectionField from '../SelectionField/SelectionField';
import SelectionWizard from '../SelectionWizard/SelectionWizard';
import { ISelectionField } from './models/ISelectionField';

@inject('appStore', 'selectionStore')
@observer
export class Selection extends Component<ISelectionProps, ISelectionState> {
	static configurationKey: 'selection' = 'selection';
	static styleKey: 'selection' = 'selection';
	constructor(props: ISelectionProps) {
		super(props);
	}
	handleChange = (field: ISelectionField, value: any) => {
		const { selectionStore } = this.props;
		if (selectionStore) {
			selectionStore.setValue(field, value);
		}
	}
	render() {
		const { selectionStore, className } = this.props;
		if (!selectionStore) {
			return null;
		}
		const { mode, getOptions, getSelectedFieldOption } = selectionStore;
		return (
			<Wrapper className={ className }>
				{ mode === 'wizard' && (
					<SelectionWizard />
				) }
				{ mode === 'default' && selectionStore.fields.map((field) => {
					const { key } = field;
					const options = getOptions(field);
					const fieldResponse = getSelectedFieldOption(field, options);
					return (
						<SelectionField
							selectionStore={ selectionStore }
							key={ key }
							field={ field }
							options={ options }
							value={ fieldResponse.value }
							onChange={ this.handleChange }
						/>
					);
				}) }
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(Selection, 'selection');

