import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import {
	IHeaderProps,
	IHeaderState,
} from './models';
import Grid from '@kurtosys/ksys-app-components/dist/components/base/Grid';
import Wrapper from './styledComponents/Wrapper';

@inject('appStore', 'headerStore')
@observer
export class Header extends Component<IHeaderProps, IHeaderState> {
	static configurationKey: 'header' = 'header';
	static styleKey: 'header' = 'header';
	constructor(props: IHeaderProps) {
		super(props);
	}
	render() {
		const { className, headerStore, appStore } = this.props;
		if (!headerStore) {
			return null;
		}
		const { components, grid } = headerStore;
		if (appStore && appStore.rawConfiguration) {
			return (
				<Wrapper className={ className }>
					<Grid grid={ grid } components={ components } />
				</Wrapper>
			);
		}
		return null;
	}
}

export default InjectedStyledComponent(Header, 'header');