import { utils } from '@kurtosys/ksys-app-template';
import { computed, action, observable } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { IFootnoteConfiguration } from '../models';
import { ITextProps } from '@kurtosys/ksys-app-components/dist/components/base/Text/models/ITextProps';

export class FootnoteStore extends StoreBase {
	static componentKey: 'footnote' = 'footnote';
	@observable.ref disclaimer: string = '';

	@computed
	get configuration(): IFootnoteConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(FootnoteStore.componentKey);
		}
	}

	@action
	async initialize(): Promise<void> {

	}

	@computed
	get text(): string {
		const text = this.configuration && this.configuration.text;
		if (!utils.typeChecks.isNullOrUndefined(text) && typeof (text) !== 'string') {
			return this.storeContext.disclaimerStore.footnoteDisclaimer;
		}
		return text || '';
	}

	@computed
	get textProps(): ITextProps | undefined {
		const textProps = this.configuration && this.configuration.textProps;
		return {
			...textProps,
			value: this.text,
		};
	}

	@computed
	get disclaimerType(): string | undefined {
		if (this.configuration && this.configuration.text && typeof (this.configuration.text) !== 'string') {
			return this.configuration.text.type;
		}
	}
}