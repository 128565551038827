import { helpers, utils } from '@kurtosys/ksys-app-template';
import { ISelectionValues } from '../components/Selection/models/ISelectionValues';

export function getSelectionsForRedirect(appParamsHelper: helpers.AppParamsHelper, rawValues: ISelectionValues, values?: ISelectionValues) {
	let selections = values || rawValues;
	if (appParamsHelper.values) {
		const { inputs } = appParamsHelper.values;
		selections = utils.object.deepMergeObjects(inputs, selections);
	}
	return selections;
}