import { TEMPLATE_CONFIGURATION } from '../../../configuration/development.templateConfig';
import { ITemplateConfiguration } from '../../../models/app/ITemplateConfiguration';
import { Manifest } from '../../../configuration/Manifest';

export class TemplateStore {
	templateConfiguration: ITemplateConfiguration | undefined;
	manifest: Manifest;
	constructor(manifest: Manifest) {
		this.manifest = manifest;
	}

	async initialize() {
		await this.getTemplateConfiguration();
	}

	get appConfigSource() {
		return (this.templateConfiguration && this.templateConfiguration.appConfigSource) || 'files';
	}

	get externalScriptsBasePath() {
		return (this.templateConfiguration && this.templateConfiguration.externalScriptsBasePath);
	}

	get cacheOptions() {
		return this.templateConfiguration && this.templateConfiguration.cacheOptions;
	}

	get assetsBaseUrl(): string {
		return this.manifest.getBaseUrl('');
	}

	async getTemplateConfiguration(): Promise<void> {
		const environment = process.env.NODE_ENV;
		if (environment !== 'development') {
			try {
				this.templateConfiguration = require('../../../configuration/templateConfig.json');
			}
			catch (ex) {
				console.error(ex);
			}
		}
		else {
			this.templateConfiguration = TEMPLATE_CONFIGURATION;
		}
	}
}