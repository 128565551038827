import React, { Component } from 'react';
import { IModalProps, IModalState } from './models';
import { inject } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';

const Backdrop = InjectedStyledComponent('div', 'modal');
const Dialog = InjectedStyledComponent('div', 'modal', ['dialog']);

@inject('appStore', 'modalStore')
export class Modal extends Component<IModalProps, IModalState> {
	static configurationKey: 'modal' = 'modal';
	static styleKey: 'modal' = 'modal';
	constructor(props: IModalProps) {
		super(props);
	}
	render() {
		const { children, open, className } = this.props;

		if (open) {
			return (
				<Backdrop className={ className }>
					<Dialog
						role="dialog"
						aria-modal="true"
						aria-labelledby="ksys-attestation-title"
					>
						{ children }
					</Dialog>
				</Backdrop>
			);
		}
		return null;
	}
}

export default Modal;
