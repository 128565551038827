import * as React from 'react';
import { InjectedStyledComponent } from '../InjectedStyledComponent';
import Image from '../../shared/styledComponents/Image';

export interface dropdownButtonToggleProps {
	className: string;
	isOpen: boolean;
}

const dropdownButtonToggle = (props: dropdownButtonToggleProps) => {
	const { className, isOpen } = props;
	const src = isOpen
		? 'DropdownChevronIconExpanded.svg'
		: 'DropdownChevronIcon.svg';
	return <Image className={className} src={src} />;
};

export default InjectedStyledComponent(dropdownButtonToggle, 'shared', [
	'dropdownButtonToggle',
]);
